import * as React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"

import { ILocation } from "../types/Location"
import { IArticleNode } from "../types/Article"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PostHeader from "../components/PostHeader"
import TableOfContents from "../components/TableOfContents"

import "./styles/article.scss"
import { getImage } from "gatsby-plugin-image"

interface IProps {
  location: ILocation
  data: {
    article: IArticleNode
    headerImage: any
  }
}

const ArticleTemplate: React.FC<IProps> = ({ data, location }) => {
  return (
      <Layout currentPath={location.pathname} readingMode={false}>
        {({ theme }) => (
          <>
            <SEO title={data.article.frontmatter.title} />

            <PostHeader
              description={data.article.frontmatter.summary ?? data.article.excerpt}
              imageData={getImage(data.headerImage)}
              theme={theme}
              title={data.article.frontmatter.title}
            />

            <div className="article-wrapper">
              <div className="article-wrapper--toc">
                <TableOfContents
                  items={data.article.tableOfContents.items}
                  theme={theme}
                />
              </div>

              <article className={`article-content--${theme}`}>
                <MDXRenderer>
                  {data.article.body}
                </MDXRenderer>
              </article>
            </div>
          </>
        )}
      </Layout>
  )
}

export const query = graphql`
  query($slug: String!, $imageName: String) {
    article: mdx(fields: { slug: { eq: $slug } }) {
      id
      body
      excerpt(truncate: true)
      frontmatter {
        category
        date
        tags
        title
        summary
      }
      tableOfContents
    }

    headerImage: file(name: { eq: $imageName }, sourceInstanceName: {eq: "images"}) {
      childImageSharp {
        gatsbyImageData(
          height: 350
          width: 350
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

export default ArticleTemplate
