import { Link } from "gatsby"
import * as React from "react"

import { ITableOfContentItem } from "../types/Article"
import { Theme } from "./ThemeToggler"

import "./styles/table-of-contents.scss"

interface TableOfContentsProps {
  items: ITableOfContentItem[]
  theme: Theme
}

function makeToc(items?: ITableOfContentItem[], level: number = 0) {
  if (items == undefined || items.length == 0) {
    return
  }

  return (
    <ul className={level == 0 ? `toc-content--list` : `toc-content--sublist`}>
      {
        items.map(item => (
            <li className="toc-item" key={item.url}>
              <Link className="toc-item--link" to={item.url}>
                <span>{item.title}</span>
              </Link>
              {item.items
                && makeToc(item.items, level + 1)}
            </li>
        ))
      }
    </ul>
  )
}

const TableOfContents: React.FC<TableOfContentsProps> = (props) => {
  return (
    <div className={`toc-wrapper--${props.theme}`}>
      <h2 className="fs24">In this article</h2>
      <nav className={`toc-content`}>
        {makeToc(props.items)}
      </nav>
    </div>
  )
}

export default TableOfContents